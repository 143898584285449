@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Reset
input,
button,
textarea {
  font-family: inherit;
}

// Once we use real avatars this can be removed
// it just makes the generated avatars less ugly
.bot-avatar {
  .avatar-image {
    object-fit: contain;
    object-position: 0 0;
    filter: none;
  }
}
.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-text);
}
.title--muted {
  color: #8f92a1;
}

.subTitle {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--grey-text);
  text-transform: uppercase;
}

.page {
  padding: 25px;
}

// Hack to make the dropdown from ui-kit align
div.bottom-center {
  transform: translate3d(0, 39px, 0) !important;
}

// Overrides checkbox of duik
.form-check:not(.toggle):not(.radio) .form-check-label:before {
  flex-shrink: 0;
}

.capitalize {
  text-transform: capitalize;
}

.fa {
  color: var(--grey-text);
}
.scroll-table-main {
  height: calc(100vh - 70px);
  background-color: white;
  overflow: scroll;
}
.scroll-area-main {
  padding: 25px;
  height: calc(100vh - 70px);
  background-color: white;
  overflow: scroll;
}
.scroll-area-tab {
  padding: 25px;
  height: calc(100vh - 141px);
  background-color: white;
  overflow: scroll;
}

.btn {
  border-color: var(--border-color-base);
  background-color: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    border-color: var(--border-color-main);
  }
  &:disabled {
    border-color: var(--border-color-main);
    background: none;
    opacity: 0.5;
  }
}
.btn--unstyled {
  background: none;
  border: none;
  box-shadow: none;
  user-select: none;
  cursor: pointer;
  font: inherit;
}
.btn--icon-only {
  padding: 0 1em;
}
.btn--icon-only:hover {
  color: var(--primary);
}
.btn-feather-icon {
  vertical-align: top;
}
.btn-feather-icon--mr {
  margin-right: 0.5em;
}
.btn-feather-icon--ml {
  margin-left: 0.5em;
}

.events-list {
  min-height: 90vh;
}

.events-item-enter {
  opacity: 0;
  max-height: 0;
}
.events-item-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in, max-height 0.5s linear;
  max-height: 50px;
}

// Modal

.btn.btn-clear {
  // Fix unclickable close button
  z-index: 1;
}
.overlay {
  z-index: 198;
}
.overlay > .modal-top {
  margin: 0;
  height: auto;
  overflow: visible;
}

// Toastify

.Toastify__toast--success {
  background: #ddf7e1;
  color: #53d769;
}
.Toastify__toast--success::before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 20a8 8 0 100-16 8 8 0 000 16zM12 16v-4M12 8h0' stroke='%2353D769' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.Toastify__toast-body {
  padding: 3px;
  font-family: gilroy, inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.Toastify__close-button {
  color: inherit;
}

// Typography

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #1e1f20;
}
h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #1e1f20;
}
h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #8f92a1;
}
p > small {
  line-height: 1.5em;
}
.label__link {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--hb-main);
}

// Forms > Label

.related {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #808080;
}

// Forms > Input

.inp {
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  line-height: 23px;
  border-radius: 6px;

  border: none;
  background-color: var(--input-bg);
  color: var(--dark-text);
}

.inp--uploaded {
  padding-left: 12px;
  align-items: center;
  text-transform: none;
  margin-bottom: 8px;
}
.inp--upload {
  padding-left: 12px;
  align-items: center;
  text-transform: none;
  color: #8f92a1;
}
.inp--upload [type='file'] {
  display: none;
}

// Select

.select--basic {
  border: 2px solid var(--input-bg);
  border-radius: 4px;
  padding-left: 4px;
  height: 26px;
  font: inherit;
  font-size: 14px;
  background-color: var(--input-bg);
  color: var(--dark-text);
}

.select--pretty .dropdown-item {
  border-bottom: 0 !important;
  font: inherit;
  font-size: 14px;
  width: 212px !important;
}

.select--pretty .dropdown-item:first-child {
  padding-top: 0.5rem;
  height: 2.875rem;
}

.select--pretty .dropdown-item:last-child {
  padding-bottom: 0.5rem;
  height: 2.875rem;
}

.select--pretty .dropdown-item b {
  margin-left: 10px;
}

// Tables

.tbl {
  flex: 1;
  overflow: 'hidden';
  font-size: 14px;
}
.tbl--mini {
  font-family: Inter, Gilroy, sans-serif;
  font-size: 13px;
  line-height: 16px;
}
.tbl tbody > tr,
.tbl tbody > tr:last-child,
.tbl thead > tr,
.tbl thead > tr:last-child {
  border-bottom: 1px solid var(--border-color-base);
}
.tbl td,
.tbl th {
  padding: 16px 12px;
}
.tbl--compact th,
.tbl--compact td {
  padding: 12px 8px;
}
.tbl--card td:first-child {
  padding-left: 0;
}
.tbl--card tbody > tr:last-child,
.tbl--card thead > tr:last-child {
  border-bottom: 0;
}
.tbl--card td:last-child {
  padding-right: 0;
}
.tbl--wide {
  width: 100%;
}
.tbl--sticky th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}
.scroll-area-tab .tbl--sticky th,
.scroll-area-main .tbl--sticky th {
  top: -26px;
}
.tbl--subtle {
  margin: 20px 0;
  border: none;

  tbody > tr,
  tbody > tr:last-child {
    border-bottom: none;
  }

  td {
    padding: 4px 16px 4px 0;
  }
}

// .tbl--avatar td,
// .tbl--avatar th {
//   &:first-of-type {
//     padding-right: 0;
//   }
//   &:first-of-type + & {
//     padding-left: 0;
//   }
// }
th,
td {
  .tbl--avatar &:first-of-type {
    padding-right: 0;
  }
  .tbl--avatar &:first-of-type + & {
    padding-left: 0;
  }
}

.tr--relevant {
  box-shadow: 3px 0 0 #dae6ff inset;
}
.th--relevant {
  font-weight: bold;
}

.tbl__border tbody > tr:last-child {
  border-bottom: 0;
}
.tbl__border {
  border: 1px solid var(--border-color-base);
  // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 0 16px;
}
.tr--clickable:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.th--sort {
  user-select: none;
  cursor: pointer;
}
.th__sort {
  display: inline-block;
  min-width: 20px;
  margin-left: 6px;
}
.tbl__sort {
  user-select: none;
  cursor: pointer;
}

.table-align-middle td {
  vertical-align: middle;
}
.td-1 {
  width: 1px;
  white-space: nowrap;
}
.td--muted {
  opacity: 0.5;
}

/* Card */
.card__action {
  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: var(--hb-main);
}

/* Other stuff */

.suggestions {
  position: relative;
}
.suggestions__list {
  top: calc(100% - 2px);
  left: 2px;
  overflow-x: auto;
  position: absolute;
  z-index: 1;
  display: inline-block;
  background: blue;
  /* border: 1px solid #eee; */
  border-radius: 6px;
  background: white;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
}
.suggestions__table {
  font-size: 14px;

  tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  td {
    padding: 0.5em;
  }
}

// Tables > Pagination

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -4px;
}

.pagination > .btn {
  margin-left: 4px;
  margin-right: 4px;
}
