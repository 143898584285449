.message {
  &-sender {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &-time-sent {
    font-size: 0.75rem;
    margin-left: 15px;
  }
}
