.summary {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: var(--bg-main);
  margin-bottom: 1px;
  width: auto;
  border-radius: 10px;
  padding: 18px 24px;
  font-size: 0.875rem;
  line-height: 1.5;
  border: 1px solid var(--border-color-base);

  strong {
    font-weight: 800;
    color: var(--dark-text);
  }

  .details {
    margin-top: 1rem;

    span {
      border-bottom: 1px solid var(--border-color-base);
      width: 100%;
      display: block;
      line-height: 21px;
      padding: 4px 0;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  summary {
    display: flex;
    justify-content: space-between;
    outline: none;
    .arrow {
      transform: rotate(-90deg);
      display: flex;
      height: 30px;
      width: 30px;
      margin-top: -5px;
      justify-content: center;
      align-items: center;
      transition: transform 400ms ease-in;
    }
  }

  &[open] {
    summary {
      .arrow {
        transform: rotate(90deg);
      }
    }
  }
}
