/* Add questionnaire */
.fillModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.radioCard {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 6vmin;
  height: 100%;
  user-select: none;
  cursor: pointer;
}
.radioCard:focus,
.radioCard:hover {
  border-color: var(--text-color);
  outline: none;
}
.radioCardActive {
  background: #f7f7f7;
  border-color: #e9e9ec !important;
}
.radioCard > p {
  color: var(--text-color);
}

/* Questionnaire Card */

.actions {
  position: absolute;
  top: 0;
  right: 0;
}

.actionsIcon {
  display: block;
  cursor: pointer;
  padding: 16px;
  transition: transform 0.2s;
}

.actionsIcon:hover {
  color: black;
}

/* Stats */

.stats {
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}
.stat {
  padding: 0;
  flex-basis: auto;
  flex-grow: 0;
  width: auto;
}
.stale {
  opacity: 0.3;
}
