.analytics-navigation-top-bar {
  justify-content: center;
}

@import '@duik/core/_vars.scss';

.analytics-nav-user {
  margin-bottom: 12px;
  padding: 24px 0 0 16px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  &-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }

  &-name {
    color: $text-main;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 1rem;
  }

  &-textTop {
    color: $text-secondary;
    font-size: 0.875rem;
  }
}

@import '@duik/core/_vars.scss';

.nav-link {
  font-size: 0.875rem;
  color: #8f92a1;
  display: block;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //border-bottom: $border-default-base;

  &-text {
    display: flex;
    align-items: center;
  }

  strong {
    margin-left: 9px;
    line-height: 1em;
    font-weight: bold;
    /* Color/5/100% */
    color: inherit;
  }
  &-icon {
    display: inline-block;
    margin-right: 12px;
    color: inherit;
    width: 18px;
    text-align: center;
    font-size: 20px;
  }

  &:hover {
    color: black;
    background-color: #00000008;

    .nav-link {
      &-icon {
        color: black;
      }
    }
  }

  // active
  &:global(.active) {
    &:global(:not(.has-sublink)) strong {
      color: $color-primary;
    }

    .nav-link {
      &-icon {
        color: $color-primary;
      }
    }
  }
}

.nav-link-text {
  padding-left: 16px;
  padding-right: 24px;
}
.nav-link-right-el {
  margin-right: 24px;
  transition: transform 0.3s;
}
:global(.nav-link--expanded) .nav-link-right-el {
  transform: rotate(180deg);
}

.panel {
  background-color: #f7f7f7;
  border: none !important;
  transition: background 0.5s, flex-basis 0.4s;
}
.sublink {
  padding-top: 10px;
  padding-bottom: 10px;
}
.panel strong {
  transition: opacity 0.4s;
}

.dev {
  background-color: #f00;
  color: white;
  border-radius: 8px;
  position: absolute;
  left: 16px;
  width: 40px;
  top: 60px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.devSelect {
  position: absolute;
  left: 72px;
  top: 36px;
}

// Collapsed state
.collapsed {
  background-color: #fff;
  flex-basis: 84px;
  overflow: hidden;
}
.collapsed .sublink {
  display: none;
}
.collapsed strong {
  opacity: 0;
}
.nav-link-icon {
  transition: transform 0.3s;
}
.analytics-nav-user {
  transition: transform 0.3s;
}
.collapsed .nav-link-icon {
  transform: translateX(14px);
}
.collapsed .analytics-nav-user {
  transform: translateX(6px);
}
