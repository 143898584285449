@import '@duik/core/_vars.scss';

.messageWrapper {
  display: flex;
  overflow: scroll;
}

.conversation-textbar {
  background-color: red;
}

.conversation-textbar {
  height: 66px;
  flex-basis: 66px;
  border-top: $border-default-base;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  background: $bg-main;

  textarea {
    outline: none;
    border: none;
    flex-grow: 1;
    resize: none;
    font: inherit;
  }
}
.chat {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f7f7f7;
  flex-basis: 400px;
  flex-grow: 2;
  flex-shrink: 1;
}
.attachment-label {
  font-size: 20px;
  position: absolute;
  padding: 22px;
  bottom: 0;
  cursor: pointer;
  right: 0;
  color: var(--text-main);
  opacity: 0.3;
  input {
    display: none;
  }
}
.attachment-label:hover {
  opacity: 1;
}

.attachment-label-loaded {
  background-color: lightgrey;
  font-size: 20px;
  position: absolute;
  padding: 1.5rem;
  bottom: 0;
  cursor: pointer;
  right: 0;
  input {
    display: none;
  }
}
