.input {
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 16px;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  line-height: 23px;
  border-radius: 6px;
  margin-bottom: 20px;

  border: none;
  background-color: var(--input-bg);
  color: var(--dark-text);
}
.badgeInput {
  user-select: none;
  align-items: center;
  padding-left: 10px;
  padding-right: 12px;
  justify-content: space-between;
}
.divInput {
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}

.limitedContainer {
  position: relative;
}
.limitedContainer > .input {
  padding-right: 60px;
}

.characterCount {
  position: absolute;
  bottom: 0;
  right: 10px;
  line-height: 40px;
  color: var(--text-color);
  font-weight: 600;
  font-size: 10px;
  opacity: 0.4;
  width: 60px;
  text-align: right;
  pointer-events: none;
}

.overLimit {
  color: var(--hb-alert);
  opacity: 1;
}
