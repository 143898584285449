.logoHeader {
  padding: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color-base);
  h2 {
    display: inline-block;
    font-weight: 800;
    margin-top: 0;
    margin-left: 2rem;
  }
}

.grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .verticalCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cell {
    padding: 2rem 25px;
    display: flex;

    p,
    h3 {
      color: var(--lighter-text);
    }
    img {
      object-fit: contain;
      margin-left: 3rem;
    }

    .buttons {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 40px;
      grid-gap: 15px;
    }

    .inputButton {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 40px;

      grid-gap: 15px;
    }
  }
}
