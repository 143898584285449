@import '@duik/core/_vars.scss';

.userInfoSidebar {
  background-color: white;
  flex-basis: 210px;
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(100vh - 70px);
  padding: 20px;
  overflow: scroll;
  font-size: 12px;
  border-left: $border-default-base;
  dt {
    text-transform: uppercase;
    color: #a5a5a6;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 20px;
  }
  dl {
    color: #4b4c4d;
  }
  dl + h4 {
    margin-top: 1.5rem;
    border-top: $border-default-base;
    padding-top: 1.5rem;
  }

  .userName {
    font-weight: bold;
    font-size: 14px;
    border-bottom: $border-default-base;
    padding-bottom: 20px;
  }
}
