.inlineButton {
  background: #ffffff;
  box-shadow: 0px 8.75px 26.25px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 4px 0 0;

  padding: 0;
  height: 30px;
  width: 30px;
}

.iconButton {
  position: absolute;
  z-index: 2;
  top: -40px;
  right: 10px;
}
