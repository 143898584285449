$itemHeight: 40px;
$itemWidth: 44px;
$gutter: 6px;
$gridWidth: 50px;

$paddingTop: 24px;
$laneHeaderWidth: 230px;

.calendar {
  position: relative;
  user-select: none;
  padding-left: 25px;
  background: #f7f7f7;
}
// .calendar * {
//   outline: 1px solid rgba(255, 0, 0, 0.2);
// }
// .laneHeader * {
//   outline: 1px solid rgba(0, 0, 255, 0.2);
// }
.scroll {
  height: calc(100vh - 141px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.scrollButtons {
  position: absolute;
  z-index: 6;
  top: $paddingTop;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  background: linear-gradient(
    269.69deg,
    #f7f7f7 70%,
    rgba(247, 247, 247, 0) 100%
  );
}
.scrollButton {
  color: #0659fd;
  cursor: pointer;
}
.scrollButton:hover {
  background-color: #fff;
  border-radius: 4px;
}

.createButton {
  position: absolute;
  top: 0;
  z-index: 5;
  padding: $paddingTop 16px 0 0;
  width: 230px;
  border-bottom-left-radius: 20px;
  background-color: #f7f7f7;
}

.createButton > :global(.outer-events-handler) {
  display: block;
}

.topRow {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  padding-top: $paddingTop;
  height: 80px;
  background: #f7f7f7 !important;
  .laneHeader {
    background: #f7f7f7 !important;
  }
}
.lanes {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.lane {
  display: flex;
  position: relative;
}
.laneGrow {
  flex-grow: 1;
}
.laneGrow .rows {
  min-height: 10px;
}
.now {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #0659fd;
}
.lane:nth-child(even) {
  background-color: #fbfbfb;
}
.topRowHeader,
.laneHeader {
  flex: 0 0 230px;
  position: sticky;
  left: 0;
  padding: 8px 16px 8px 8px;
  // margin-right: 16px;
  background: #f7f7f7;
  z-index: 2;
}
.laneHeader::after {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 50px;
  background: linear-gradient(
    90deg,
    rgba(151, 162, 172, 0.1) 0%,
    rgba(196, 196, 196, 0) 100%
  );
}
.topRow .laneHeader::after {
  display: none;
}

.lane:nth-child(even) .laneHeader {
  background-color: #fbfbfb;
}

.laneHeaderContent {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  align-items: center;
}
.laneHeaderButton {
  padding-right: 12px;
}

.laneIcon {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  padding: 5px;
  margin-right: 6px;
  color: #0659fd;
}
.laneHeaderContent + .title {
  margin-top: 18px;
  user-select: text;
}
.title + .title {
  margin-top: 16px;
  user-select: text;
}

.title {
  padding: 0 12px;
  height: $itemHeight;
  line-height: $itemHeight;
  background: #f7f7f7;
  font-weight: bold;
  font-size: 12px;
  border-radius: 4px;
}

.rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  flex: 0 0 auto;
  padding: 8px 0;
  background-image: url(/images/grid-50x5.png);
  overflow-x: hidden;
}
.row {
  height: $itemHeight;
  position: relative;
}
.row + .row {
  margin-top: 4px;
}
.activity {
  position: absolute;
  z-index: 1;
  height: $itemHeight;
  line-height: 12px;
  background: #d9d9d9;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.status_scheduled {
  background: #0659fd;
}
.status_completed {
  background: #52e8a9;
}
.status_not-started {
  background: #d9d9d9;
}
.activityTitle {
  padding-top: 6px;
  padding-left: 5px;
  white-space: pre;
  overflow-x: hidden;
}
.activityDescription {
  padding-top: 6px;
  padding-left: 5px;
  white-space: pre;
  overflow-x: hidden;
  opacity: 0.5;
}
.bell {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 11px;
  height: 11px;
  padding: 2px;
  background-color: #fff;
  border-radius: 11px;
}

.spot {
  position: absolute;
  top: 0;
  height: $itemHeight;
  width: $itemWidth;
  border: 2px dashed rgba(6, 89, 253, 0.2);
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
}
.spot:hover {
  opacity: 1;
}

.dayRender {
  display: flex;
}
.dayNumber {
  color: #4d4d4d;
  flex: 0 0 $gridWidth;
  display: flex;
  flex-direction: row;
  justify-content: center;

  font-size: 12px;
  line-height: 24px;
  width: 24px;
  pointer-events: none;
}
// .dayActive {
//   font-weight: 600;
//   line-height: 24px;
//   width: 24px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #0659fd;
//   color: white;
//   border-radius: 24px;
// }
.dayActive {
  position: relative;
}
.dayActive::after {
  content: '';
  position: absolute;
  left: 11px;
  top: 24px;
  width: 1px;
  background: #0659fd;
  height: 100vh;
}
.dayLetter {
  font: inherit;
  color: #a5a5a6;
  margin-right: 2px;
}
// .weekLine {
//   position: relative;
// }
// .weekLine::after {
//   content: '';
//   position: absolute;
//   left: -1px;
//   top: 50px;
//   width: 2px;
//   background: rgba(196, 196, 196, 0.2);
//   height: 100vh;
// }

.month {
  display: inline-block;
  position: sticky;
  left: 246px;
  margin-right: 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  color: #787979;
}
