.pill {
  color: var(--white-text);
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 2px 10px 0;
  text-transform: uppercase;
  // line-height: normal;

  border-radius: 23px;
  margin-bottom: 2px;
}

.pill.nails {
  background: #f9b853;
}

.pill.hair {
  background: #0658fd;
}

.pill.skin {
  background: #44a0ff;
}

.pill.validated {
  display: inline-flex;
  background: #f7f7f7;
  color: #4b4c4d;
}

.pill.review {
  display: inline-flex;
  background: #ff4e4e;
}
