.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding: 0px 50px;
}

.memberCount {
  display: inline-block;
  width: 120px;
  color: var(--text-main);
  font-weight: bold;
}

.table {
  flex: 1;
  overflow: 'hidden';
}

.avatarUsers {
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.button {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;
}

.darker {
  color: var(--text-main);
  font-weight: bold;
}

.selectText {
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: auto;
  user-select: all;
  font-family: monospace;
  padding: 6px;
  font-size: 12px;
  line-height: 1em;
  background: #f4f6f9;
  vertical-align: top;
  margin: -4px 0;
}
.copyText {
  vertical-align: top;
  margin: -4px 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 1em;
  text-transform: uppercase;
  border: 0;
  background: #f4f6f9;
  padding: 6px;
  margin-left: 2px;
}
.copyText:focus,
.selectAll:hover .copyText {
  background: var(--primary);
  color: white;
}
.thSortable {
  user-select: none;
}
