.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 250ms ease;
  &:hover {
    transform: scale(1.2);
    //   color: black;
  }
}
