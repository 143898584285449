.btn {
  width: 100%;
  height: 100%;
  color: var(--primary);
  background: none;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-size: inherit;
  font-family: inherit;
  padding: 10px 20px;
  line-height: 20px;
  border-radius: 0;
  margin: 0;
  text-align: center;

  transition: background-color 200ms ease-out;

  &:disabled {
    opacity: 0.5;
  }
}

.primary {
  color: var(--primary);

  &:hover {
    color: black;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.subtle {
  background-color: #fff;
  color: #777;

  &:hover {
    color: #000;
  }
}

:global(.table-button-td) {
  width: 1px;
  height: 100%;
  padding: 0 !important;
  position: relative;
  vertical-align: middle !important;
}
