@import '@duik/core/_vars.scss';

body {
  font-family: Gilroy, Inter, Helvetica Neue, Segoe UI, sans-serif;
  --primary: #0659fd;
  --white-bg: #fff;
  --grey-bg: #f2f2f2;
  --input-bg: #f7f7f7;
  --dark-text: #1e1f20;
  --lighter-text: #4b4c4d;
  --light-text: #a5a5a6;
  --white-text: #fff;
  --grey-text: #787979;
  --color-red: #e6492d;

  // 2021 Design system
  --hb-main: #0659fd;
  --hb-secondary: #f9b853;
  --hb-dark: #1e1f20;
  --hb-grey: #8f92a1;
  --hb-light: #f7f7f7;
  --hb-white: #fff;
  --hb-success: #1e1f20;
  --hb-alert: #ff4e4e;

  // Use this
  --input-bg: var(--hb-light);
  --input-color: var(--hb-dark);
  --input-focus: #0659fd80;
  --bg-depth: var(--hb-light);
  --bg-nav: var(--hb-light);
  --bg-card: var(--hb-white);
  --border-soft: var(--hb-light);
  --text-color: var(--hb-dark);
  --text-nav: #4b4c4d;

  .avatar-image {
    outline: 5px solid red;
    object-fit: none !important;
    object-position: 0 0 !important;
  }
}

// Typography

a {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-main;
    &:hover {
      color: $color-primary;
    }
  }
}
