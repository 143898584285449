@import '@duik/core/_vars.scss';

.bottom-message {
  height: 1rem;
}
.wrap {
  flex-basis: 400px;
  flex-grow: 2;
  flex-shrink: 1;
  padding: 2rem;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

.conversation-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

@media screen and (max-width: 1150px) {
  .conversation-back {
    display: block;
  }

  .conversation-avatars {
    display: none;
  }

  .container {
    overflow: hidden;

    & > * {
      flex-basis: 100% !important;
      width: 100% !important;
      max-width: none !important;
      flex-shrink: 0 !important;
      transition: transform 0.5s ease;
      transform: translateX(0);
    }

    &.containerActiveConversation {
      & > * {
        transform: translateX(0%);
        transform: translateX(-100%);
      }
    }
  }
}
