.login-page {
  background-color: var(--grey-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 1em;

  h2 {
    margin-top: 0;
    font-size: 30px;
    font-weight: bold;
    color: var(--dark-text);
  }

  .login-wrap {
    width: 100%;
    max-width: 445px;
    position: relative;
    color: var(--dark-text);
    background-color: var(--white-bg);
    margin-top: 80px;
    padding: 40px 20px 20px;
    border-radius: 10px;
    p {
      color: var(--light-text);

      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    img {
      position: absolute;
      top: -60px;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    @media (min-width: 445px) {
      padding: 40px 40px 20px;
    }
  }

  .error {
    color: var(--color-red);
  }
  p.info {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: var(--lighter-text);

    strong {
      font-weight: bold;
    }
  }
  p.go-check {
    margin-top: 11px;
  }

  .secondaryActions {
    margin-top: 10px;
    margin-bottom: 40px;
    p {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      margin-top: 7px;
    }
    button {
      background: none;
      border: none;
      color: var(--primary);
      font-family: inherit;
      font-weight: 500;
    }
  }
}
