.reviewsTitle {
  span {
    width: 25%;
    display: inline-block;
    text-align: right;
    color: var(--lighter-text);
    &:first-of-type {
      text-align: left;
      color: var(--dark-text);
    }
  }
}
.reviewsRow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .reviewRowCol {
    width: 25%;
    text-align: right;
    color: var(--lighter-text);
    &:first-of-type {
      text-align: left;
      color: var(--dark-text);
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid var(--border-color-base);
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: var(--dark-text);
  &:last-of-type {
    border-bottom: none;
  }
}

.hyperlink {
  color: var(--primary);
  text-decoration: underline;
}
