.tblSubtle {
  margin: 20px 0;
  border: none;

  tbody > tr,
  tbody > a {
    border: none;
  }

  th {
    padding: 0;
  }

  td {
    padding: 8px 0;
  }
}

.answer {
  display: flex;
}

.answer + .answer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.status {
  display: inline-block;
  position: relative;
  padding: 10px;
  margin: -10px 0;
}
.statusCircle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.statusLabel {
  position: absolute;
  top: 3px;
  left: 30px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  padding: 4px 8px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.2s, transform 0.2s;
}

.status:hover .statusLabel,
.status:focus .statusLabel {
  opacity: 1;
  transform: none;
}

.card {
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // elevation: hidden ? 1 : 10;
  padding-left: 14px;
  padding-right: 14px;
  background-color: white;
  margin-bottom: 12px;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    background-color: #f7f7f7;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 2px solid #b3b3b3;

  &:focus,
  &:active,
  &:hover {
    border-color: #1e1f20;
  }
}
