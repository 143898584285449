.modal {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 0;
  z-index: 1000;
}

.modalInModal {
  background-color: #00000088;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  z-index: 1000;
  align-items: center;

  .twoButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  .aboutToSave {
    background-color: white;
    width: 550px;
    padding: 1.5rem;
    border-radius: 10px;
  }
}

.header {
  display: flex;

  margin-bottom: 2rem;
  width: 80%;
  position: relative;

  .halfHeader {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
  }
}
.left {
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3rem;
  box-sizing: border-box;
}
.right {
  padding: 3rem;
  width: 350px;
  //background: red;
}
.wrap {
  margin-left: 20%;
  // background-color: white;
  // height: 533px;
  // width: 800px;
  // position: relative;
  // background-color: white;
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--grey-bg);
}
.imagecanvas {
  position: absolute;
  left: 20%;
  width: 80%;
  height: 80vh;
}
.canvas2 {
  position: absolute;
  left: 20%;
  width: 80%;
  height: 80vh;
}

.toolButtonWrap {
  justify-content: space-between;
  display: flex;
  button {
    height: 40px;
    font-family: inherit;
    border-radius: 10px;
    background-color: #cddeff;
    color: var(--primary);
    border: none;
    outline: none;
    padding: 0 1rem;
    &:active,
   // &:focus,
    &:hover {
      background: #407bfe;
    }
  }
}
.toolButton {
  width: 40px;
  padding: 0 !important;
  margin-right: 10px;

  &.active {
    background: #407bfe;
  }
}
.colorButton {
  border: 3px solid white;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  outline: none;

  &:focus {
    outline: none;
  }
}

.textarea {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -1px 0px #f1f1f3;
  border: 1px solid #d2d3d9;
  box-sizing: border-box;
  border-radius: 10px;
  resize: none;
  height: 44vh;
  padding: 1rem;
  line-height: 1.5rem;

  outline: none;
}
