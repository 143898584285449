@import '@duik/core/_vars.scss';

.pictureModal {
  font-size: 14px;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  button {
    // display: flex;
    // justify-content: space-between;
    // font-family: inherit;
    // font-weight: 600;
  }
  img {
    height: 100%;
    // width: 60vh;
    max-width: 70vw;
    object-fit: contain;
    background: white;
  }
}
.imgWrap {
  height: 80vh;
  display: flex;
  position: relative;
}

.downloadContainer {
  padding: 2rem;
  position: relative;
  width: 300px;
  background: white;

  h3 {
    margin: 0;
    text-transform: capitalize;
  }
}

.details {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: $border-default-base;
}

.details-row {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  strong {
    font-weight: bold;
  }
}
