.card {
  position: relative;
  // border: 1px solid var(--border-color-base);
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  background: white;
  color: var(--text-main);
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 25px;
  margin: 22px 0 40px;
}

.cardGrid .card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card h2 {
  font-weight: 700;
}
