.calendarSidebar {
  flex: 0;
  max-width: 100%;
  flex-basis: 350;
  right: 0;
  width: 350px;
  bottom: 0;
  top: 145px;
  z-index: 7;
  position: fixed;
  background-color: #f7f7f7;
  transition: transform 0.3s;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
.calendarSidebar.collapsed {
  transform: translateX(350px);
}
