@import '@duik/core/_vars.scss';

.badge {
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  padding: 1px 7px 0;
  height: 29px;
  user-select: none;
}
.badge + .badge {
  margin-left: 12px;
}

.primary {
  background: #cddeff;
  color: var(--primary);
}

.error {
  background: red;
  color: white;
}

.muted {
  background: #e9e9ec;
  color: #8f92a1;
}
:global(a:hover) > .muted {
  background: #ddd;
  color: #678;
}

.tag {
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
}

.sm {
  line-height: inherit;
  vertical-align: top;
  display: inline-block;
  padding: 0px 4px;
  font-size: 10px;
  box-sizing: border-box;
  height: initial;
}

.airtable {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L1.3 3.6c-.4.1-.4.6 0 .7L7 6.6c.5.2 1 .2 1.6 0l6-2.4c.2-.1.3-.6 0-.7l-6-2.4C8.2.8 7.6.8 7.1 1zM8.4 7.9v5.8c0 .3.3.5.6.4l6.5-2.6c.2 0 .3-.2.3-.3V5.3c0-.3-.3-.5-.5-.4L8.7 7.5c-.2 0-.3.2-.3.4zM6.9 8.2l-2 .9-.2.1-4.1 2c-.3.1-.6 0-.6-.4V5.3l.1-.2.1-.1h.4l6.2 2.4c.4.2.4.6 0 .8z' fill='%238F92A1'/%3E%3Cpath d='M.4 5H.2l4.7 4.1 2-1 .2-.3c0-.1 0-.3-.3-.4L.6 5H.4z' fill='%238F92A1'/%3E%3C/svg%3E");
  background-position: 12px 50%;
  border-radius: 30px;
  padding-left: 36px;
  padding-right: 12px;
}
