/* Panes */
.full {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.panes {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.questions {
  flex: 10 1 0;
  padding: 25px;
  height: calc(100vh - 70px);
  overflow: scroll;
  background: #f7f7f7;
}
.main {
  flex: 1 1 250px;
  padding: 25px;
  background: white;
  height: calc(100vh - 70px);
  overflow: auto;
}
.saves {
  display: flex;
  justify-content: space-between;
}
.saves > button {
  width: auto;
}

/* Pills tab navigation */

.pills {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.inner {
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
}
.pill {
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-main);
  border-radius: 6px;
}
.pill + .pill {
  margin-left: 8px;
}
.pill:hover {
  background-color: var(--primary);
  color: var(--white-text);
}
.active {
  background-color: var(--primary);
  color: var(--white-text);
  pointer-events: none;
}

/* Questions > Card */
.question {
  margin: 12px auto;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 800px;
}
.questionTitle {
  display: flex;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
}
.collapsed .title {
  font-size: 14px;
  font-weight: 500;
}
.collapsed form {
  display: none;
}
.expanded {
  padding-bottom: 1px;
}
.expanded .questionTitle {
  padding-bottom: 16px;
  border-bottom: 2px solid #f7f7f7;
  min-height: 48px;
}
.expanded .title {
  font-size: 12px;
}
.expanded .linkId {
  visibility: hidden;
}
.expanded:hover .linkId,
.expanded.linked .linkId {
  visibility: visible;
}
.body {
  font-size: 14px;
}

.original {
  border-left: 2px solid #0659fd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  margin: -10px 0 10px;
  padding: 6px 12px;
  background: rgba(205, 222, 255, 0.2);
  color: #4b4c4d;
}
.original::before {
  content: attr(data-language);
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #0659fd;
}
/* Questions > Insert question button */
.insert {
  position: relative;
  min-height: 60px;
  display: block;
  background: none;
  border: none;
  text-align: center;
  width: 100%;
  appearance: none;
}
.insert:focus {
  outline: none;
}
.insert::before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  width: 2px;
  height: 100%;
  background: #d2d3d9;
  border-radius: 1px;
}
.insertBorder {
  display: inline-block;
  border-radius: 35px;
  border: 1px solid #d2d3d9;
  width: 20px;
  height: 12px;
}
.insertLabel {
  position: relative;
  z-index: 1;
  display: inline-block;
  border: 1px solid;
  border-radius: 35px;
  width: 100%;
  height: 10px;
  line-height: 8px;
  vertical-align: top;
  background: white;
  color: #d2d3d9;
  transition: transform 0.2s, color 0.2s;
}
.insert:focus > .insertBorder > .insertLabel,
.insert:hover > .insertBorder > .insertLabel {
  color: var(--primary);
  transform: scale(2);
}
.insertLabel > svg {
  opacity: 0;
  display: block;
  transition: opacity 0.2s;
  height: 8px;
  width: 100%;
}
.insert:focus > .insertBorder > .insertLabel > svg,
.insert:hover > .insertBorder > .insertLabel > svg {
  opacity: 1;
}
.line {
  position: relative;
  min-height: 24px;
  display: block;
  background: none;
  border: none;
  text-align: center;
  width: 100%;
  appearance: none;
}
.line::before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  width: 2px;
  height: 100%;
  background: #d2d3d9;
  border-radius: 1px;
}

// End of questionnaire
.another {
  display: flex;
  justify-content: center;
  margin: 12px 0;
}
