.btn {
  color: var(--white-text);
  background-color: var(--primary);
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  padding: 10px 20px;
  line-height: 20px;
  border-radius: 6px;
  margin-bottom: 1rem;
  text-align: center;
  height: 40px;

  transition: background-color 200ms ease-out;
  &:focus,
  &:active,
  &:hover {
    background-color: #cddeff;
    color: var(--primary);
  }

  &:disabled {
    background-color: #cddeff;
    color: var(--primary);
    opacity: 0.5;
  }
}

.secondary {
  background-color: #cddeff;
  color: var(--primary);

  &:focus,
  &:active,
  &:hover {
    color: var(--white-text);
    background-color: var(--primary);
  }
  &:disabled {
    background-color: #cddeff;
    color: var(--primary);
    opacity: 0.5;
  }
}

.subtle {
  background-color: transparent;
  color: #777;

  &:focus,
  &:active,
  &:hover {
    color: #000;
    background-color: #eee;
  }
}

.primarySubtle {
  background-color: transparent;
  color: var(--primary);

  &:focus,
  &:active,
  &:hover {
    color: #000;
    background-color: transparent;
  }
}

.success {
  background-color: #53d769;
  color: var(--white-text);

  &:focus,
  &:active,
  &:hover {
    color: var(--white-text);
    background-color: #75df87;
  }

  &:disabled {
    background-color: #53d769 !important;
    color: var(--white-text);
    cursor: not-allowed;
    opacity: 1;
  }
}

.error {
  background-color: #ffe4e3;
  color: #ff4e4e;

  &:focus,
  &:active,
  &:hover {
    color: var(--white-text);
    background-color: #ff4e4e;
  }
}

.inline {
  display: inline-block;
  width: auto;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

:global(.modal-footer) {
  .btn {
    margin-bottom: 0;
  }
}
