@import '@duik/core/_vars.scss';

.flex1 {
  flex: 1;
}
.nextPageContainer {
  padding: 20px 20px 0;
}
.conversations {
  border-right: $border-default-base;
  flex-basis: 300px;
  max-width: 300px;
  flex-shrink: 0;
  height: calc(100vh - 70px);
}

.conversation-list {
  display: flex;
  flex-direction: column;
  background: $bg-main;

  > .flex1 > * {
    border-bottom: $border-default-base;
    margin: 0;
    flex-shrink: 0;
  }
}
.conversation-list-item {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  border-left: 3px solid white;
}

.conversation-list-item-unread .thread-user-name::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: var(--primary);
  float: left;
  margin-right: 6px;
  margin-top: 5px;
}

.conversation-list-item-active {
  border-left: 3px solid $color-primary;
  background-color: mix(#0659fd, #fff, 5%);
}

.thread-user-name {
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  color: #4b4c4d;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.last-message {
  font-size: 12px;
  line-height: 21px;
  color: #4b4c4d;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.generated-avatar {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: inline-block;
  overflow: hidden;
  margin-right: 1rem;
}
