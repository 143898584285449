.input {
  display: block;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  line-height: 24px;
  border-radius: 6px;
  color: var(--dark-text);
  background-color: var(--input-bg);
}
.input p {
  font: inherit;
  color: inherit;
  background-color: inherit;
}
.inset {
  margin: -8px -12px;
}
.subtle {
  color: inherit;
  background-color: none;
  font: inherit;
  line-height: inherit;
}
.subtle:hover,
.subtle:focus,
.subtle:focus-within {
  color: var(--dark-text);
  background-color: var(--input-bg);
}

.wrapper {
  display: block;
  position: relative;
  line-height: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  background: none;
  outline: none;
}
.textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: inherit;
  padding: inherit;
  font: inherit;
  line-height: inherit;
  color: inherit;
  background: none;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  outline: none;
  text-transform: inherit;
}
.div {
  display: block;
  flex-grow: 1;
  white-space: pre;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  pointer-events: none;
}
.div::before {
  content: attr(data-value) ' ';
  visibility: hidden;
  opacity: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
