.input {
  display: block;
  margin: -8px;
  padding: 8px;
  border-radius: 6px;
  transition: box-shadow 0.3s;
}
:global(label:hover) > .input,
.input:hover,
.input:focus,
.input:focus-within {
  color: var(--dark-text);
  // background-color: var(--input-bg);
  background-color: rgba(0, 0, 0, 0.04);
}
.input:focus,
.input:focus-within {
  box-shadow: 0 0 0 2px #dae6ff;
}
.wrapper {
  display: block;
  position: relative;
  line-height: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  background: none;
  outline: none;
}
.textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: inherit;
  padding: inherit;
  font: inherit;
  line-height: inherit;
  color: inherit;
  background: none;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  outline: none;
  text-transform: inherit;
}
.div {
  display: block;
  flex-grow: 1;
  white-space: pre;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  pointer-events: none;
}
.div::before {
  content: attr(data-value) ' ';
  visibility: hidden;
  opacity: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
