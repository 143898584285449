@import '@duik/core/_vars.scss';

.avatarUsers {
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.darker {
  color: var(--text-main);
  font-weight: bold;
}
.anonymous {
  position: relative;
  padding-bottom: 15px;
}
.anonymous::after {
  content: 'anonymous';
  background: #eee;
  font-size: 10px;
  position: absolute;
  top: 15px;
  padding: 0 5px;
  border-radius: 20px;
}
.name {
  display: flex;
  min-height: 30px;
  line-height: 15px;
  align-items: center;
}
.arrow {
  display: inline-block;
  position: relative;
  padding: 3px;
  transition: transform 0.2s;
}
.sortDown {
  transform: rotate(-90deg);
}
.sortUp {
  transform: rotate(90deg);
}
.pushHeight {
  min-height: 100vh;
}
.avatarTable {
  font-size: 12px;
  width: 100%;
}
.avatarTable th:first-of-type {
  // push name over to the right because avatar takes in place.
  padding-left: 60px;
}

.clickableRow {
  color: inherit;
  display: table-row;
  cursor: pointer;
}
// .clickableRow:hover {
//   background: #f5f5f5;
// }

.tdInput {
  padding-top: 0;
  padding-bottom: 0;
}
.tdInput > input {
  margin: 0;
  padding-right: 8px;
}
.thSortable {
  user-select: none;
}
.nowrap {
  white-space: nowrap;
}

// User detail > Care plan
// User detail > Care plan > Activity

.btnSubtle {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  vertical-align: top;
  line-height: inherit;
  font: inherit;
  color: var(--primary);
}
.btnSubtle:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}
.btnSubtle:disabled {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}
.small {
  font-size: 14px;
}

:global(.btn.dropdown-item).highDropdownItem {
  flex-direction: column;
  height: 3.5em;
  padding-top: 6px;
  line-height: 1.2em;
  align-items: flex-start;
  justify-content: center;
}
.highDropdownItem small {
  font-weight: bold;
  opacity: 0.7;
}
.timeDistance {
  font-size: 14px;
  opacity: 0.4;
}
.engagement {
  position: relative;
  left: 15px;
  padding-left: 10px;
  border-left: 2px solid rgb(223, 223, 235);
}
.engagement,
.engagement + :global(.col-9) {
  font-size: 85%;
  margin-bottom: 0;
}

//

.confirmChanges {
  padding: 10px 25px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 0;
  background: white;
  border-top: 1px solid var(--border-soft);
}

// User detail > Events

.event {
  display: flex;
  white-space: nowrap;
}
.eventSentAt {
  flex: 0 0 200px;
}
.eventName {
  flex: 0 0 240px;
}
.eventProperties {
  margin-left: 200px;
}

.eventProperty {
  margin: 2px 10px;
}

.kindDropdownItem {
  border-bottom: 0 !important;
  font: inherit;
  font-size: 14px;
  width: 212px !important;
}

.kindDropdownItem:first-child {
  padding-top: 0.5rem;
  height: 2.875rem;
}

.kindDropdownItem:last-child {
  padding-bottom: 0.5rem;
  height: 2.875rem;
}

.kindDropdownItem b {
  margin-left: 10px;
}
