.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    position: absolute;
    padding: 7px 8px;

    border-radius: 5px;
  }
  input {
    border: 1px solid var(--border-color-base);
    margin-right: 3rem;
    height: 2rem;
    padding-left: 25px;
    border-radius: 3px;
  }
}
