.darkCard {
  background: #f7f7f7;
  box-shadow: none;
}
.darkCard > h4 {
  letter-spacing: 0.7px;
}

.value {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: var(--dark-text);
}

.infoText {
  font-size: 10px;
  line-height: 21px;
  color: var(--grey-text);
  margin-left: 5px;
}

.topGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid var(--border-color-base);
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: var(--dark-text);
  &:last-of-type {
    border-bottom: none;
  }
}

.suggestionTitle {
  width: 60%;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  width: 150px;
  display: inline-flex;
  & > button {
    border-color: transparent;
  }
}

/* Block configuration */
.draggable {
  margin: 10px 0;
  line-height: 1.2em;
  border-radius: 8px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  button {
    margin: -10px -10px -10px 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
