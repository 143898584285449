.mandatory-page {
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-main);

    text-transform: none;
    input {
      display: none;
    }
    span {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      border-radius: 26px;
      background-color: var(--grey-bg);
      margin-right: 1.5rem;
    }
    span:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      border-radius: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + span {
      background-color: var(--primary);
    }

    input:focus + span {
      box-shadow: 0 0 1px var(--primary);
    }

    input:checked + span:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  h2 {
    margin-top: 0;
    font-size: 30px;
    font-weight: bold;
    color: var(--dark-text);
  }
}

.mandatory-wrap {
  color: var(--dark-text);
  background-color: var(--white-bg);
  padding: 30px 20px 10px;
  p {
    color: var(--light-text);
    font-size: 16px;
    line-height: 22px;
  }
  img {
    float: right;
    width: 40px;
    height: 40px;
    margin-top: -10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}

.roundedAvatar {
  border-radius: 99px;
}

@media (min-width: 550px) {
  .mandatory-page {
    background-color: var(--grey-bg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mandatory-wrap {
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 80px 0;
    padding: 40px 40px 20px;
    border-radius: 10px;

    img {
      position: absolute;
      top: -60px;
      left: 0;
      float: none;
      margin-top: 0;
      width: 40px;
      height: 40px;
    }
  }
}
